<template>
  <v-container>
    <v-card class="" elevation="" max-width="">
      <v-card-text>
        <v-data-table :headers="headers" :items="items" show-select> </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-dialog transition="dialog-transition">
          <template v-slot:[`activator`]="{ on, attrs }">
            <v-btn small color="primary" block v-on="on" v-bind="attrs">Solicitar</v-btn>
          </template>
          <v-card class="" elevation="" max-width="">
            <v-card-title primary-title> Selecciona el tipo de alamacenamiento a solicitar </v-card-title>
            <v-card-text>
              <v-select
                color
                :items="['Fijo', 'Baja rotación', 'Temporal']"
                filled
                label="Tipo de almacenaje"
              ></v-select>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="primary"> Solicitar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    headers: [
      { text: 'nombre', align: 'start', value: 'nombre' },
      { text: 'codigo', align: 'start', value: 'codigo' },
      { text: 'Almacenaje actual', align: 'start', value: 'actual' },
    ],
    items: [
      { id: 1,nombre: 'Producto 1', codigo: 'AAA-34', actual: 'Fijo (180)' },
      { id: 2,nombre: 'Producto 2', codigo: 'AAA-35', actual: 'Fijo (180)' },
    ],
  }),
}
</script>
